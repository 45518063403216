const common = {    
    Bucket: 'lin-1304790867',
    Region: 'ap-shanghai',
    ID:'AKID3vYu1tVrxmLfjW3JI0SdXAdlcCVYLr52',
    Key:'jBMHuGL9gxxgBPM2RZQzHJ4YliOnwpSn',
    BaseImagePath:'scrap/',
    isWeiXin:'',
    gzchengshu_key:'9c514660d9836caa2944476c49170eab',
    ext_arr:['.jpg','.jpeg','.gif','.png','.tif'],    
  }
  export default common
import Vue from 'vue';
import Router from 'vue-router';
const commonIndex =()=> import('./view/commonIndex');

Vue.use(Router);

const routes = [
  // {
  //   path: '*',
  //   redirect: '/goods'
  // }
  // ,
  {
    name: 'magenthome',
    path:'/magenthome',
    component: () => import('./view/mAgentHome/mAgentHome'),
    meta: {
      title: '扳手互联'
    },
  },
  {
    name: 'morderdetail',
    path:'/morderdetail',
    component: () => import('./view/orderDetail/orderDetail'),
    meta: {
      title: '报废详情'
    },
  },      
  {
    name: 'mindex',
    path:'/mindex',
    component: () => import('./view/mIndex/mIndex'),
    meta: {
      title: '扳手互联'
    }
  },
  {
    name:'preferential',
    path: '/preferential',
    component: () => import('./view/preferential/preferential'),
    meta:{
      title: '优惠券'
    }
  },
  { 
    name: 'question',
    path: '/question',
    component:() => import('./view/customerQuestion/customerQuestion.vue'),
    meta:{
      title: '提交问卷'
    }
  },
  { 
    name: 'userPay',
    path: '/userPay',
    component:() => import('./view/userPay/userPay.vue'),
    meta:{
      title: '加盟支付'
    }
  },
  { 
    path: '/userBill', 
    name: 'userBill', 
    component:()=> import('./view/muserBill/userBill'),
    meta:{
      title: '我的账单'
    }
  },
  { 
    path: '/myQrcode', 
    name: 'myQrcode', 
    component:()=> import('./view/myQrcode/myQrcode'),
    meta:{
      title: '我的二维码'
    }
  },
  { 
    path: '/uploadOrder', 
    name: 'uploadOrder', 
    component:()=> import('./view/upload/uploadOrder'),
    meta:{
      title: '提交线索'
    }
  },
  { 
    path: '/orderPricing', 
    name: 'orderPricing', 
    component:()=> import('./view/orderPricing/orderPricing'),
    meta:{
      title: '线索价格'
    }
  },
  { 
    path: '/bank', 
    name: 'bank', 
    component:commonIndex,
    children:[
      { 
        path: 'balance', 
        name: 'balance', 
        component: ()=>import('./view/bank/balance'),
        meta:{title: '我的余额'}
      },
      { 
        path: 'withdraw', 
        name: 'withdraw', 
        component: ()=>import('./view/bank/withdrawApply'),
        meta:{title: '我的余额'}
      },
      {
        path:'',
        name:'',
        component:()=>import('./view/bank/bankIndex'),
        meta:{title: '银行卡列表'}
      },
      {
        path:'checkBankUser',
        name:'checkBankUser',
        component:()=>import('./view/bank/checkBankUser'),
        meta:{title: '绑定银行卡'}
      },
      {
        path:'auth',
        name:'auth',
        component:()=>import('./view/bank/auth'),
        meta:{title: '选择用户类型'}
      },
      {
        path:'companyAuth',
        name:'companyAuth',
        component:()=>import('./view/bank/companyAuth'),
        meta:{title: '企业认证'}
      },
      {
        path:'bindPhone',
        name:'bindPhone',
        component:()=>import('./view/bank/bindPhone'),
        meta:{title: '绑定手机'}
      },
      {
        path:'realName',
        name:'realName',
        component:()=>import('./view/bank/realNameValid'),
        meta:{title: '个人认证'}
      },
      {
        path:'signStatus',
        name:'signStatus',
        component:()=>import('./view/bank/signStatus'),
        meta:{title: '签约结果'}
      },
      {
        path:'add',
        name:'add',
        component:()=>import('./view/bank/bankAdd'),
        meta:{title: '添加银行卡'}
      },
      { 
        path: 'wallet', 
        name: 'wallet', 
        component: ()=>import('./view/wallet/wallet'),
        meta:{title: '我的钱包'}
      },
    ],
  },
  { 
    path: '/magent', 
    name: 'magent', 
    component:commonIndex,
    children:[
      { 
        path: 'register',
        name: 'register',
        component:()=> import('./view/register/register'),
        meta:{title: '注册'}
      },  
      { 
        path: 'business',
        name: 'business',
        component:()=> import('./view/register/business'),
        meta:{title: '业务类型'}
      }, 
      { 
        path: 'hazardousWaste',
        name: 'hazardousWaste',
        component:()=> import('./view/hazardousWaste/hazardousWaste'),
        meta:{title: '危废合作'}
      },  
    ]
  },
  { 
    path: '/pricingLogin', 
    name: 'pricingLogin', 
    component:()=> import('./view/pricingLogin/pricingLogin'),
    meta:{
      title: '定价员登录'
    }
  },
  { 
    path: '/pricingIndex', 
    name: 'pricingIndex', 
    component:()=> import('./view/pricingIndex/pricingIndex'),
    meta:{
      title: '定价员登录'
    }
  },
  { 
    path: '/pricingPhotos',
    name: 'pricingPhotos',
    component:()=> import('./view/pricingPhotos/pricingPhotos'),
    meta:{
      title:'拖车照片上传'
    }
  },
  { 
    path: '/pricingQuestion',
    name: 'pricingQuestion',
    component:()=> import('./view/pricingQuestion/pricingQuestion'),
    meta:{
      title:'信息确认'
    }
  },
  { 
    path: '/pricingQuestion',
    name: 'pricingQuestion',
    component:()=> import('./view/pricingQuestion/pricingQuestion'),
    meta:{
      title:'信息确认'
    }
  },
  {
    path: '/qrcode',
   component: commonIndex,
   children:[{ path: 'bind', 
      name: 'bind',
      component: () => import('./view/qrcode/qrcode')
    }]
  },
  {
     path: '/personal',
     name: 'personal',
     component:()=>import("./view/personalLogin/personalLogin"), 
  },
  {
    path: '/personalIndex',
    name: 'personalIndex',
    component:()=>import("./view/personalIndex/personalIndex"), 
 },
 
 {
  name:'userpart',
  path: '/userpart',
  component: commonIndex,
  children:[
      { path: 'upload', 
        name: 'upload',
        component: () => import('./view/userParts/upload'),
        meta:{title: '旧件回收'}
      },
      // { path: 'info', 
      //   name: 'info',
      //   component: () => import('./view/userParts/info'),
      //   meta:{title: '报废结果'}
      // },
      { 
        path: 'settlement', 
        name: 'settlement', 
        component: () => import('./view/userParts/settlement'),
        meta:{title: '报废结果'}
      },
    ]
  },
  {
    name:'scrapPieces',
    path: '/scrapPieces',
    component: commonIndex,
    children:[
        { path: 'login', 
          name: 'scrapPiecesIndex',
          component: () => import('./view/scrapPieces/login'),
          meta:{title: '登录'}
        },
        { path: 'index', 
          name: 'scrapPiecesIndex',
          component: () => import('./view/scrapPieces/index'),
          meta:{title: '拆车件回收'}
        },
        { path: 'question', 
          name: 'scrapPiecesQuestion',
          component: () => import('./view/scrapPieces/question'),
          meta:{title: '拆车件回收'}
        }
      ]
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  // base:'/test/',
mode:'history', routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};

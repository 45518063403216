const $enum = {    
    wx_appid:"wx1f77b250a8447b4d",
    scrapTypeEnum:{
        0:'轿车',
        1:'卡车',
        2:'大客车',
        3:'面包车'
    },
    orderStatus : {
        0:'客户提交',
        10:'详情回填',
        20:'处理中',
        30:'客服回访',
        40:'已分配',
        50:'代理商回访',
        60:'申请关闭',
        70:'申请完成',
        80:'订单关闭',
        90:'订单完成',
        '-1':'作废'
    },   
    userOrderStatus:{
        0:'已提交',
        10:'问卷已填写',
        20:'客服已回访',
        40:'已预约上门',
        70:'已付款',
        90:'已出证'
    },
    myOrderStatus:{
        0:'提交',
        10:'问卷已填写',
        20:'客服处理中',
        30:'客服已回访',
        40:'线索已分配',
        50:'上门收车中',
        60:'订单待关闭',
        70:'已付款',
        80:'订单已关闭',
        90:'已出证',
        '-1':'作废'
    },
    userPartOrderStatusEnum:{
        0:'已提交',
        20:'已接单',
        50:'已预约上门',
        70:'已付款',
        90:'订单完成'
    },
    mileageOptions:{
        0:'10万-15万',
        1:'16万-20万',
        2:'20万以上'
    },
    //定价员流程状态
    transactionStatusEnum:{
        0:'待处理',
        1:'待审核',
        2:'已打款'
    },
    BillTypeEnum:{
        0:'加盟费',
        1:'线索提成',
        2:'报废回收费',
        3:'拆解厂充值',
        4:'报废收款'
    },
    BusinessScopeEnum:{
        0:'保养维修',
        1:'车辆美容',
        2:'二手车交易',
        3:'其它'
    },
    cardTypeEnum:{
        1:'借记卡',
        2:'信用卡'
    },
    bankNameEnum:{
        1:'工商银行',
        2:'农业银行',
        3:'中国银行',
        4:'建设银行',
        5:'交通银行',
        6:'邮储银行',
    },
    wasteJoinStatusEnum:{
        0:'未加盟',
        1:'已加盟',
        2:'已过期',
        3:'未填写'
    },
    IdentityTypeEnum:{
        1:"身份证",
        2:"护照",
        3:"军官证",
        4:"回乡证",
        5:"台胞证",
        6:"警官证",
        7:"士兵证",
        8:"户口簿",
        9:"港澳居民来往",        
    },
    UserSourceEnum:{
        1:'扳手互联',
        2:'爱拆车'
    },
    payTypeEnum:[
        {name:'微信充值',value:"WECHAT_PUBLIC_ORG"},
        {name:'银行卡充值',value:"QUICKPAY_VSP"},
        // {name:'微信充值',value:"WECHAT_PUBLIC_ORG"},
    ]
    // mileageOptions:{
    //     0:'未加盟',
    //     1:'已加盟'
    // },
  }
  export default $enum
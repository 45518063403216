<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import util from '../src/helper/util'
import Enum from "./helper/enum"
import Cookies from 'js-cookie'
export default {
  name: 'App',
  components:{
  },
  data(){
    return{}
  },
  methods:{
  },
  mounted(){
  },
  created(){
    this.$global.isWeiXin = util.isWeiXin()
    Cookies.set('appid',Enum.wx_appid);
  }
}
</script>
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>

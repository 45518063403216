import global  from "./global"
import COS  from 'cos-js-sdk-v5'
  const common = {    
    checkPhone(phone){ 
        if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))){             
            return false; 
        }else{
            return true;
        } 
    },
    loadTxImg(img){
      return img?process.env.VUE_APP_COS_BASE_URL+img:'';
    },
    setCookie(key,value,t){
      var oDate=new Date();
      //t 天
      oDate.setDate(oDate.getDate()+t);
      document.cookie=key+"="+JSON.stringify(value) +"; expires="+oDate.toDateString();
    },
    getCookie(key){
      var arr,reg=new RegExp("(^| )"+key+"=([^;]*)(;|$)");
      if(arr==document.cookie.match(reg))
        return unescape(arr[2]);
      else 
        return null;
    },
    removeCookie(key){
      this.setCookie(key,"",-1); 
    },
    delAllCookie () {
      var keys = document.cookie.match(/[^ =;]+(?==)/g)
      if (keys) {
        for (var i = keys.length; i--;) {
          document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
          document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
          document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
        }
      }
    },
    //判断是否是微信浏览器的函数
  isWeiXin(){
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        return true;
      } else{
        return false;
      }
    },
    isMobile() { 
      var userAgentInfo=navigator.userAgent; 
      var Agents =new Array("Android","iPhone","SymbianOS","Windows Phone","iPad","iPod"); 
      var flag=true; 
      for(var v=0;v<Agents.length;v++) { 
         if(userAgentInfo.indexOf(Agents[v])>0) { 
           flag=false; 
           break; 
         } 
       } 
       return flag; 
    },
    guid() { 
      return (this.S4()+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+this.S4()+this.S4()); 
    },
    S4() { 
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
    },
    uploadFile(ext,fileobj, callback){
      console.log('guid');
      var guid = this.guid();
      console.log(guid);
      var dataURLtoBlob = function (dataurl) {
          var arr = dataurl.split(',');
          var mime = arr[0].match(/:(.*?);/)[1];
          var bstr = atob(arr[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
      };
        const img = new Image();
        img.src = fileobj.content||fileobj;
        img.onload = function() {
            // 缩放图片需要的canvas
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            // 图片原始尺寸
            const originWidth = img.width;
            const originHeight = img.height;
            // 最大尺寸限制
            const maxWidth = 900;
            const maxHeight = 900;
            console.log(originWidth+" "+ originHeight);
            // 目标尺寸
            let targetWidth = originWidth;
            let targetHeight = originHeight;
            // 图片尺寸超过限制
            if (originWidth > maxWidth || originHeight > maxHeight) {
                if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                } else {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                }
            }
            // canvas对图片进行缩放
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            console.log(targetWidth+" "+ targetHeight);
            // 清除画布
            context.clearRect(0, 0, targetWidth, targetHeight);
            // 图片压缩
            context.drawImage(img, 0, 0, targetWidth, targetHeight);
            var dataCompress = canvas.toDataURL('image/jpeg');
            var body = dataURLtoBlob(dataCompress);
            var cos = new COS({
                SecretId: global.ID,
                SecretKey: global.Key,
            });
            cos.putObject({
              Bucket: global.Bucket, /* 必须 */
              Region: global.Region,     /* 存储桶所在地域，必须字段 */
              Key: "scrap/"+ guid +ext,              /* 必须 */
              StorageClass: 'STANDARD',
              Body: body, // 上传文件对象
              onProgress: function(progressData) {
                  console.log(JSON.stringify(progressData));
              }
              }, function(err, data) {
                console.log('error',err);
                  callback('err',data);
                  console.log(err || data);
            });
        };        
    },
    uploadFileEl(ext,fileobj, callback){
        console.log('guid');
        var guid = this.guid();
        console.log(guid);
        var cos = new COS({
            SecretId: global.ID,
            SecretKey: global.Key,
        });
        cos.putObject({
          Bucket: global.Bucket, /* 必须 */
          Region: global.Region,     /* 存储桶所在地域，必须字段 */
          Key: "scrap/"+ guid +ext,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: fileobj, // 上传文件对象
          onProgress: function(progressData) {
            console.log(JSON.stringify(progressData));
          }
          }, function(err, data) {
              callback(err,data);
              console.log(err || data);
      });
    },
    validUplodImgExt(ext){
      var t = global.ext_arr.filter(e=>{
        return e == ext;
      });
      if(t.length!=0){
        return true;
      }else{
        return false;
      }
    },
    parseDateFull(date){
      if(!date){
        return '';
      }
      var time = date.toString().replace('T',' ');      
      var datetime = new Date(time);
      if(datetime.getFullYear().toString()<2002){
        return '';
      }
      var year = datetime.getFullYear().toString();
      if(isNaN(year)){
        datetime = new Date(date);
        year = datetime.getFullYear().toString();
      }
      var month = datetime.getMonth().toString() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
      var day = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      var hour = datetime.getHours()< 10 ? "0" + datetime.getHours() : datetime.getHours();
      var minute = datetime.getMinutes()< 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
      var second = datetime.getSeconds()< 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
      return year + "-" + month + "-" + day+" "+hour+":"+minute+":"+second;
    },
    isJSON(str) {
      if (typeof str == 'string') {
            try {
                var obj=JSON.parse(str);
                if(typeof obj == 'object' && obj ){
                    return true;
                }else{
                    return false;
                }
            } catch(e) {
                console.log('error：'+str+'!!!'+e);
                return false;
            }
        }else{
          return true;
        }
    },
    fileToBase64(file,fun){
      var reader = new FileReader();
      reader.onload = function() {
          var img = new Image();
          img.src = reader.result;
          img.onload = function() {
              var w = img.width,
                  h = img.height;
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              canvas.width = w;
              canvas.height = h;              
              ctx.drawImage(img, 0, 0, w, h);
              var base64 = canvas.toDataURL('image/jpeg', 0.5);
              var result = {
                  url: window.URL.createObjectURL(file),
                  base64: base64,
                  clearBase64: base64.substr(base64.indexOf(',') + 1),
                  suffix: base64.substring(base64.indexOf('/') + 1, base64.indexOf(';')),
              };
              fun(result);
          }
      }
      reader.readAsDataURL(file);
    },
    DrawImage(event) {
      var ImgD=event.target, width_s=148, height_s=147;
      console.log('DrawImage',ImgD);
      var image = new Image();
      image.src = ImgD.src;
      if (image.width > 0 && image.height > 0) {
            ImgD.width = width_s;
            ImgD.height = height_s;
            return;
      }
  },judgeBrand(sUserAgent) {
      return sUserAgent.match(/huawei/i) == "huawei";
  },
  IdentityCodeValid(code) { 
      var city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};
      // var tip = "";
      var pass= true;
      if(!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)){
          pass = false;
      }else if(!city[code.substr(0,2)]){
          pass = false;
      }else{
          //18位身份证需要验证最后一位校验位
          if(code.length == 18){
              code = code.split('');
              //∑(ai×Wi)(mod 11)
              //加权因子
              var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
              //校验位
              var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
              var sum = 0;
              var ai = 0;
              var wi = 0;
              for (var i = 0; i < 17; i++)
              {
                  ai = code[i];
                  wi = factor[i];
                  sum += ai * wi;
              }
              if(parity[sum % 11] != code[17]){
                  // tip = "校验位错误";
                  pass =false;
              }
          }
      }
      return pass;
  },
  isIOS(){
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return true
      }else{
        return false
      }
    }
  }
  export default common
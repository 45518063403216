import Vue from 'vue';
import App from './App';
import { router } from './router';
import global from './helper/global'
import {Dialog, Toast } from 'vant';
import $enum from './helper/enum'
import util from './helper/util'

Vue.use(Toast,Dialog);
Vue.prototype.$global = global;
Vue.prototype.$enum = $enum;
Vue.prototype.$util = util;
Vue.prototype.$cosImg = (url)=>{
  if(url){
    return process.env.VUE_APP_COS_BASE_URL+url
  }else{
    return 'image'
  }
}
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
